import React from 'react';
import { Helmet } from 'react-helmet';

import HomePageV2 from "./landing-page";
import Layout from '../components/layout';

import './styles.scss';
import '../styles/main.scss';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Tuyển dụng Unicloud</title>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content="Career Unicloud. Tuyển dụng Unicloud." />
        <meta property="og:title" content="Career Unicloud. Tuyển dụng Unicloud" />
        <meta property="og:description" content="Career Unicloud. Tuyển dụng công việc. Cơ hội nghề nghiệp dành cho bạn tại Unicloud. Tìm hiểu ngay tại đây!" />
        <meta property="og:url" content="https://career.unicloud.com.vn/" />
        <meta property="og:images" content="/favicon-32x32.png" />
        <meta property="og:site_name" content="Tuyển dụng Unicloud" />
        <meta property="article:author" content="https://www.facebook.com/UnicloudCareers" />
        <meta property="article:tag" content="Tuyển dụng Unicloud" />
        <meta property="article:tag" content="career Unicloud" />
        <meta property="article:section" content="Tuyển dụng" />
        <meta property="article:images" content="/favicon-32x32.png" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <main>
        <Layout>
          <HomePageV2 />
        </Layout>
      </main>
    </>
  );
};

export default IndexPage;
